import React, {useState} from 'react'
import { DataGrid, GridColDef, GridSortDirection } from '@mui/x-data-grid'

const flattenObject = (
  obj: Record<string, any>,
  parentKey = '',
  result: Record<string, any> = {}
) => {
  for (const [key, value] of Object.entries(obj)) {
    const newKey = parentKey ? `${parentKey}_${key}` : key
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      flattenObject(value, newKey, result)
    } else {
      result[newKey] = value
    }
  }
  return result
}

interface DataGridTableProps {
  data: Array<Record<string, any>>
  actionButtons: GridColDef[]
  rowsPerPageOptions?: number[]
  sortField?: string
  sortBy?: GridSortDirection
}

const DataTable: React.FC<DataGridTableProps> = ({
  data,
  actionButtons,
  rowsPerPageOptions = [5, 10, 25],
  sortField,
  sortBy
}) => {
  const flattenedData = data.map((item) => flattenObject(item))
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(0)

  const columns: GridColDef[] =
    flattenedData.length > 0
      ? Object.keys(flattenedData[0]).map((key) => ({
          field: key,
          headerName: key.replace(/_/g, ' ').toUpperCase(),
          flex: 1,
          minWidth: 100,
        }))
      : []

  const allColumns = [...columns, ...actionButtons]

  return (
    <div style={{height: '100%', width: '100%'}}>
      <DataGrid
        rows={flattenedData}
        columns={allColumns}
        paginationModel={{page, pageSize}}
        onPaginationModelChange={(model) => {
          setPage(model.page)
          setPageSize(model.pageSize)
        }}
        pageSizeOptions={rowsPerPageOptions}
        sortModel={[
          {
            field: sortField || '', // Column to sort by
            sort: sortBy || 'desc', // Sort order (asc or desc)
          },
        ]}
      />
    </div>
  )
}

export default DataTable
