import { AlertColor } from '@mui/material/Alert'
import { boolean } from 'yup'
import { setFintracData, setJsonReportDetails } from '../../store/features/formsSlice'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { useAuth } from '../auth'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as _ from 'lodash'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Alert from '@mui/material/Alert'
import axios from 'axios'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FintracPageFormReportDetails from './FintracPageFormComponents/FintracPageFormReportDetails'
import FintracPageFormSubjects from './FintracPageFormComponents/FintracPageFormSubjects'
import FintracPageFormTransactions from './FintracPageFormComponents/FintracPageFormTransactions'
import React, { useEffect, useState } from 'react'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import Typography from '@mui/material/Typography'

const API_URL = process.env.REACT_APP_API_URL

const FintracPageForm: React.FC = () => {
  const { merchant, transactionId } = useParams()
  const dispatch = useDispatch()
  const formData = useAppSelector((state) => state.forms)
  const formStatus = formData?.fintracFormValidation
  const fintracData = useAppSelector((state) => state.forms.fintracData)
  const [document, setDocument] = useState()
  const [isReportValid, setIsReportValid] = useState(boolean)
  const { auth } = useAuth()
  const [loading, setLoading] = useState(false)
  const [openSnackbar, setOpenSnackBar] = useState(false)
  const [reportSubmitted, setReportSubmitted] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [jsonReport, setJsonReport] = useState()
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('success')

  useEffect(() => {
    if (jsonReport) {
      const fetchReport = async () => {
        const url = `${API_URL}/get-fintrac-json-report`
        const response = await axios.post(url, { 'path': jsonReport })
        dispatch(setJsonReportDetails(response.data.content))
      }
      fetchReport()
    }
  }, [jsonReport, dispatch])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${API_URL}/transaction-details/${transactionId}`)

        if (result.data && result.data.length > 0) {
          setDocument(
            _.get(result.data?.[0], 'submerchant.compliance_form.business_registration_document')
          )
          setJsonReport(result.data?.[0]?.fintrac_report?.report_json_file)
          setReportSubmitted(result.data?.[0].fintrac_report ? true : false)

          const transactionDetails = _.map(result.data, (item: any) => ({
            // Top-level fields
            id: item.id,
            amount: item.amount,
            submerchant_id: item.submerchant_id,
            created_at: item.created_at,
            report_count: item.fintrac_report_count,
            is_submitted: item.fintrac_report ? true : false,

            // Submerchant information
            dba_name: _.get(item, 'submerchant.dba_name'),
            natureOfPrincipalBusiness: _.get(item, 'submerchant.nature_of_principal_business'),

            // Business document
            documentNumber: _.get(item, 'submerchant.compliance_form.document_number'),
            issuingCountry: _.get(item, 'submerchant.compliance_form.issuing_country'),
            issuingProvinceState: _.get(item, 'submerchant.compliance_form.issuing_province_state'),

            // Address details
            address: _.get(item, 'submerchant.address'),
            address_line1: _.get(item, 'submerchant.address.line1'),
            address_line2: _.get(item, 'submerchant.address.line2'),
            city: _.get(item, 'submerchant.address.city'),
            state: _.get(item, 'submerchant.address.state'),
            postal_code: _.get(item, 'submerchant.address.postal_code'),

            // Business user details
            first_name: _.get(item, 'submerchant.business_user.first_name'),
            last_name: _.get(item, 'submerchant.business_user.last_name'),
            email: _.get(item, 'submerchant.business_user.email'),
          }))

          dispatch(setFintracData(transactionDetails))
        } else {
          // setError('No transactions found for the given report.')
        }
      } catch (err) {
        console.error(err)
      } finally {
      }
    }

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
        }
        return Promise.reject(error)
      }
    )

    fetchData()

    return () => {
      axios.interceptors.response.eject(interceptor)
    }
  }, [dispatch])

  interface ValidationIndicatorProps {
    isValid: boolean
  }

  const ValidationIndicator = ({ isValid }: ValidationIndicatorProps): JSX.Element | null => {
    return isValid ? (
      <TaskAltIcon sx={{ color: 'green' }} />
    ) : (
      <CancelOutlinedIcon sx={{ color: 'red' }} />
    )
  }

  useEffect(() => {
    setIsReportValid(
      formStatus?.isFormReportDetailsValid &&
      formStatus?.isFormSubjectValid &&
      formStatus?.isFormTransactionsValid
    )
  }, [
    setIsReportValid,
    formStatus?.isFormReportDetailsValid,
    formStatus?.isFormSubjectValid,
    formStatus?.isFormTransactionsValid,
  ])

  const handleViewDocument = async () => {
    try {
      const url = `${API_URL}/view-business-document`
      const response = await axios.post(url, { document })
      if (response.data?.url) {
        window.open(response.data.url, '_blank')
      } else {
        console.error('Failed to fetch document URL')
      }
    } catch (error) {
      console.error('An error occurred:', error)
    }
  }

  const handleSubmitReport = async () => {
    setLoading(true)
    // Format and add additional data
    const reportFormData = formData.fintracForm

    const formattedData = {
      reportFormData: JSON.stringify(reportFormData), // Spread the existing form data
      submerchantId: fintracData?.[0]?.submerchant_id,
      transactionId: fintracData?.[0]?.id,
      processedByUserId: auth?.user?.id,
      reportReferenceNumber: reportFormData.reportDetails.reportingEntityReportReference,
      natureOfPrincipalBusiness: reportFormData?.definitions?.[2]?.natureOfPrincipalBusiness,
      documentNumber: reportFormData?.definitions?.[2]?.identifications?.[0]?.number,
      documentIssuingCountry:
        reportFormData?.definitions?.[2]?.identifications?.[0]?.jurisdictionOfIssueCountryCode,
      documentIssuingProvinceState:
        reportFormData?.definitions?.[2]?.identifications?.[0]
          ?.jurisdictionOfIssueProvinceStateCode,
    }

    try {
      const ENDPOINT = reportSubmitted ? 'update-report' : 'submit-report'
      const response = await axios.post(`${API_URL}/${ENDPOINT}`, formattedData, {
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
        },
      })

      setLoading(false)
      setOpenSnackBar(true)
      setAlertSeverity('success')
      setAlertMessage('Report has been submitted')
      // window.location.reload()
    } catch (error) {
      setOpenSnackBar(true)
      setAlertSeverity('error')
      setAlertMessage('Report submission failed!')
      // throw error // Handle the error as needed
    } finally {
      setLoading(false)
    }
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenSnackBar(false)
  }

  return (
    <div>
      <Box component='section' sx={{ p: 2 }}>
        <h1>
          Fintrac Report Form: {merchant}{' '}
          <Button
            sx={{ float: 'right' }}
            variant='contained'
            onClick={() => handleViewDocument()}
            disabled={!document || false}
          >
            {!document ? 'Document not avaiable' : 'View business document'}
          </Button>
        </h1>
      </Box>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1-content'
          id='panel1-header'
        >
          <Typography gutterBottom variant='h5' component='div'>
            Report Details <ValidationIndicator isValid={formStatus?.isFormReportDetailsValid} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FintracPageFormReportDetails />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2-content'
          id='panel2-header'
        >
          <Typography gutterBottom variant='h5' component='div'>
            Subjects <ValidationIndicator isValid={formStatus?.isFormSubjectValid} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FintracPageFormSubjects />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2-content'
          id='panel2-header'
        >
          <Typography gutterBottom variant='h5' component='div'>
            Transactions <ValidationIndicator isValid={formStatus?.isFormTransactionsValid} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FintracPageFormTransactions />
        </AccordionDetails>
      </Accordion>
      <Box component='section' sx={{ p: 2 }}>
        <h1>
          <Button
            sx={{ float: 'right' }}
            variant='contained'
            onClick={() => handleSubmitReport()}
            disabled={!isReportValid || false || loading}
          >
            {loading ? 'Submitting...' : reportSubmitted ? 'Update Report' : 'Submit Report'}
          </Button>
        </h1>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertSeverity} variant='filled' sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default FintracPageForm
