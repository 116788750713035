import {transactionsSchema} from './schemas/validation-schemas'
import {transformTransactionsData} from './schemas/transform-data'
import {updateForm} from '../../../store/features/formsSlice'
import {useAppSelector} from '../../../../hooks/useAppSelector'
import {useDispatch} from 'react-redux'
import {useForm} from 'react-hook-form'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import Box from '@mui/material/Box'
import CustomDatetimePicker from '../../components/CustomDatetimePicker'
import dayjs from 'dayjs'
import DropDownMenu from '../../components/DropDownMenu'
import Grid from '@mui/material/Grid2'
import options from './select-options'
import React, {useEffect, useMemo} from 'react'
import TextInput from '../../components/TextInput'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'

type FormData = z.infer<typeof transactionsSchema>

const FintracPageFormTransactions: React.FC = () => {
  const formData = useAppSelector((state) => state.forms)
  const fintracData = useAppSelector((state) => state.forms.fintracData)
  const dispatch = useDispatch()

  const {
    control,
    watch,
    setValue,
    register,
    formState: {errors, isValid},
    trigger,
  } = useForm<FormData>({
    resolver: zodResolver(transactionsSchema),
    mode: 'onBlur',
    defaultValues: {
      eftTypeCode: 2,
      regulatoryExceptionIndicator: false,
      thresholdIndicator: true,
      reportingEntityLocationId: '1',
      typeCode: 2,
      sourceOfFundsObtainedIndicator: false,
      amount: '',
      beneficiaryTypeCode: 4,
      receiverTypeCode: 8,
      currencyCode: 'CAD',
    },
  })

  const optionsInitiatorSubjectItems = useMemo(() => {
    const definitions: {refId: string; nameOfEntity: string; typeCode: number}[] =
      formData.fintracForm?.definitions || []
    return Object.values(definitions)
      .filter((data) => data?.refId != null)
      .map((data) => ({
        value: data.refId,
        label: data.nameOfEntity,
        typeCode: data.typeCode,
      }))
  }, [formData.fintracForm?.definitions])

  useEffect(() => {
    const item4 = optionsInitiatorSubjectItems.find((i) => i.typeCode === 4)
    const item2 = optionsInitiatorSubjectItems.find((i) => i.typeCode === 2)
    const item8 = optionsInitiatorSubjectItems.find((i) => i.typeCode === 8)

    if (item4) {
      setValue('beneficiaryRefId', item4.value)
      setValue('requesterRefId', item4.value)
    }
    if (item2) setValue('refId', item2.value)
    if (item8) setValue('receiverRefId', item8.value)
  }, [optionsInitiatorSubjectItems, setValue])

  useEffect(() => {
    trigger() 
  }, [trigger])

  useEffect(() => {
    const subscription = watch((value, {name}) => {
      trigger(name) 
      const transformedData: ReturnType<typeof transformTransactionsData> =
        transformTransactionsData(value)
      dispatch(updateForm({formKey: 'fintracForm', data: transformedData}))
    })
    return () => subscription.unsubscribe()
  }, [watch, trigger, dispatch])

  // Update form validity
  useEffect(() => {
    dispatch(
      updateForm({
        formKey: 'fintracFormValidation',
        data: {isFormTransactionsValid: isValid},
      })
    )
  }, [isValid, dispatch])

  useEffect(() => {
    setValue('dateTimeOfTransaction', fintracData?.[0]?.created_at)
    setValue('reportingEntityTransactionReference', fintracData?.[0]?.id)
    setValue('amount', fintracData?.[0]?.amount)
  }, [fintracData, setValue])

  return fintracData ? (
    <div>
      <Box component='section' sx={{pr: 2, pl: 2, pb: 2, border: '1px', bgcolor: 'white'}}>
        <form>
          <Typography variant='h6'>Electronic Funds Transaction Details</Typography>

          <Grid container spacing={2} sx={{mt: '10px'}}>
            <Grid size={2}>
              <DropDownMenu
                name='eftTypeCode'
                control={control}
                disabled={true}
                errors={errors?.eftTypeCode}
                label='Report Type'
                options={options.eftTypeCodeItems}
                type={'number'}
              />
            </Grid>
            <Grid size={3}>
              <CustomDatetimePicker
                control={control}
                defaultValue={dayjs()}
                name={'dateTimeOfTransaction'}
                errors={errors?.dateTimeOfTransaction}
                label={'Transaction date and time'}
              />
            </Grid>
            <Grid size={2}>
              <DropDownMenu
                name='regulatoryExceptionIndicator'
                control={control}
                disabled={true}
                errors={errors?.regulatoryExceptionIndicator}
                label='Regulatory Exception applied to this transaction?'
                options={options.regulatoryExceptionAppliedItems}
                type={'boolean'}
              />
            </Grid>
            <Grid size={2}>
              <DropDownMenu
                name='thresholdIndicator'
                control={control}
                disabled={true}
                errors={errors?.thresholdIndicator}
                label='Threshold Indicator'
                options={options.thresholdIndicatorItems}
                type={'boolean'}
              />
            </Grid>
            <Grid size={3}>
              <TextInput
                label='Reporting Entity Transaction Reference'
                name='reportingEntityTransactionReference'
                register={register}
                errors={errors?.reportingEntityTransactionReference}
              />
            </Grid>
          </Grid>

          <Box sx={{mt: 1}}>
            <Typography variant='h6'>Initiator</Typography>
          </Box>
          <Grid container spacing={2} sx={{mt: '10px'}}>
            <Grid size={4}>
              <DropDownMenu
                name='reportingEntityLocationId'
                errors={errors?.reportingEntityLocationId}
                label='Reporting Entity Location'
                options={options.initiatorLocationItems}
                control={control}
              />
            </Grid>
            <Grid size={4}>
              <DropDownMenu
                name='typeCode'
                errors={errors?.typeCode}
                label='Subject Type'
                options={options.initiatorTypeCodeItems}
                control={control}
                type={'number'}
              />
            </Grid>
            <Grid size={4}>
              <DropDownMenu
                name='refId'
                errors={errors?.refId}
                label='Subject reference'
                options={optionsInitiatorSubjectItems}
                control={control}
              />
            </Grid>
          </Grid>

          <Box sx={{mt: 2}}>
            <Typography variant='h6'>Starting Actions</Typography>
          </Box>
          <Grid container spacing={2} sx={{mt: '10px'}}>
            <Grid size={3}>
              <TextInput label='Amount' name='amount' register={register} errors={errors?.amount} />
            </Grid>
            <Grid size={3}>
              <DropDownMenu
                name='currencyCode'
                errors={errors?.currencyCode}
                label='Currency'
                options={options.currencyCodeItems}
                control={control}
              />
            </Grid>
            <Grid size={3}>
              <DropDownMenu
                name='sourceOfFundsObtainedIndicator'
                errors={errors?.sourceOfFundsObtainedIndicator}
                label='Was information about the source of funds obtained? '
                options={options.trueFalseItems}
                control={control}
              />
            </Grid>
            <Grid size={3}>
              <DropDownMenu
                name='requesterRefId'
                errors={errors?.requesterRefId}
                label='Requester'
                options={optionsInitiatorSubjectItems}
                control={control}
              />
            </Grid>
          </Grid>

          <Box sx={{mt: 2}}>
            <Typography variant='h6'>Completing Actions</Typography>
          </Box>
          <Grid container spacing={2} sx={{mt: '10px'}}>
            <Grid size={3}>
              <DropDownMenu
                name='beneficiaryTypeCode'
                errors={errors?.beneficiaryTypeCode}
                label='Beneficiary Type'
                options={options.subjectTypeItems}
                control={control}
              />
            </Grid>
            <Grid size={3}>
              <DropDownMenu
                name='beneficiaryRefId'
                errors={errors?.beneficiaryRefId}
                label='Beneficiary'
                options={optionsInitiatorSubjectItems}
                control={control}
              />
            </Grid>
            <Grid size={3}>
              <DropDownMenu
                name='receiverTypeCode'
                errors={errors?.receiverTypeCode}
                label='Receiver Type'
                options={options.subjectTypeItems}
                control={control}
              />
            </Grid>
            <Grid size={3}>
              <DropDownMenu
                name='receiverRefId'
                errors={errors?.receiverRefId}
                label='Receiver'
                options={optionsInitiatorSubjectItems}
                control={control}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  ) : (
    <Box sx={{pt: 0.5}}>
      <Skeleton />
      <Skeleton width='60%' />
    </Box>
  )
}

export default FintracPageFormTransactions
