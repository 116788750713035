import * as _ from 'lodash';

const adjustDate = (date) => {
    const validDate = new Date(date);
    if (isNaN(validDate)) {
        // throw new Error("Invalid date input");
        return;
    }

    // Format the date in the fintrac desired format (e.g., 2023-10-31T00:00:00-03:00)
    const options = {
        timeZone: 'America/Toronto',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // Ensure 24-hour format (military time)
        timeZoneName: 'short'
    };

    const dateString = validDate.toLocaleString('en-US', options);
    const [month, day, year, hour, minute, second, timeZone] = dateString.split(/[/,\s:]+/);
    const timezoneOffset = timeZone === 'EST' ? '-05:00' : '-04:00';
    return `${year}-${month}-${day}T${hour}:${minute}:${second}${timezoneOffset}`;
};

export const transformReportDetailsData = (data) => {
    return {
        "reportDetails": {
            "reportTypeCode": data.reportTypeCode,
            "submitTypeCode": data.submitTypeCode,
            "activitySectorCode": data.activitySectorCode,
            "reportingEntityNumber": data.reportingEntityNumber,
            "submittingReportingEntityNumber": data.submittingReportingEntityNumber,
            "reportingEntityReportReference": data.reportingEntityReportReference,
            "twentyFourHourRule": {
                "aggregationTypeCode": data.aggregationTypeCode,
                "periodStart": adjustDate(data.periodStart),
                "periodEnd": adjustDate(data.periodEnd)
            },
            "reportingEntityContactId": data.reportingEntityContactId,
            "eftDirectionCode": 1,
            // "ministerialDirectiveCode": data.ministerialDirectiveCode
        },
    }
}

export const transformDefinitionsData = (data) => {
    const refId = (data) => _.join(_.map(data.split(' '), word => _.capitalize(word)), '');

    return {
        "definitions": [
            // Initiator = Type Code: 2
            {
                "typeCode": Number(data.subjectInitiatorTypeCode),
                "refId": refId(data.subjectInitiatorEntityName),
                "nameOfEntity": data.subjectInitiatorEntityName
            },
            // Receiver = Type Code: 8
            {
                "typeCode": Number(data.subjectReceiverTypeCode),
                "refId": `${refId(data.subjectReceiverEntityName)}_${data.subjectReceiverTypeCode}`,
                "nameOfEntity": data.subjectReceiverEntityName,
                "addressTypeCode": 2,
                "address": {
                    "typeCode": 2,
                    "countryCode": data.subjectReceiverCountryTypeCode,
                    "unstructured": data.subjectReceiverEntityAddress
                }
            },
            // Merchant = Type Code: 4
            {
                "typeCode": Number(data.subjectMerchantTypeCode),
                "refId": `${refId(data.subjectMerchantEntityName)}_${data.subjectMerchantTypeCode}`,
                "nameOfEntity": data.subjectMerchantEntityName,
                "addressTypeCode": Number(data.subjectMerchantEntityAddressType),
                "address": {
                    "typeCode": Number(data.subjectMerchantEntityAddressType),
                    "countryCode": data.subjectMerchantEntityCountry,
                    "unstructured": data.subjectMerchantEntityAddress
                },
                "natureOfPrincipalBusiness": data.subjectMerchantNatureOfPrincipalBusiness,
                "registrationIncorporationIndicator": data.subjectMerchantRegistrationIndicator,
                "registrationsIncorporations": [
                    {
                        "typeCode": Number(data.subjectMerchantIncorporationRegistrationTypeCode),
                        "number": data.subjectMerchantRegistrationIncorporationNumber,
                        "jurisdictionOfIssueCountryCode": data.subjectMerchantIncorporationIssuingCountry,
                        "jurisdictionOfIssueProvinceStateCode": data.subjectMerchantIncorporationIssuingProvinceState
                    },
                ],
                "identifications": [
                    {
                        "identifierTypeCode": Number(data.subjectMerchantIdentificationType),
                        "number": data.subjectMerchantIdentificationNumber,
                        "jurisdictionOfIssueCountryCode": data.subjectMerchantIdIssuingCountry,
                        "jurisdictionOfIssueProvinceStateCode": data.subjectMerchantIdIssuingProvinceState
                    }
                ],
                "authorizedPersons": [
                    {
                        "surname": data.subjectMerchantSurname,
                        "givenName": data.subjectMerchantGivenName,
                        // "otherNameInitial": data.subjectMerchantOtherNameInitial
                    },
                ]
            },

        ]
    }
};

export const transformTransactionsData = (data) => {
    return {
        "transactions": [
            {
                "electronicFundsTransactionDetails": {
                    "eftTypeCode": data.eftTypeCode,
                    "thresholdIndicator": data.thresholdIndicator,
                    "regulatoryExceptionIndicator": data.regulatoryExceptionIndicator,
                    "dateTimeOfTransaction": adjustDate(data.dateTimeOfTransaction),
                    "reportingEntityTransactionReference": data.reportingEntityTransactionReference,
                    "additionalPartiesSendingIndicator": false,
                    "additionalPartiesEffectingSwiftIndicator": false,
                    "additionalPaymentInformations": [],
                    "fundsOriginatingOutsideCanadaIndicator": false,
                    "fundsReceivedOrInitiatedWithinInstitutionCode": 0,
                    "initiatorAndReceiverIndicator": false
                },
                "initiator": {
                    "typeCode": data.typeCode,
                    "refId": data.refId,
                    "details": {
                        "reportingEntityLocation": {
                            "id": data.reportingEntityLocationId
                        }
                    }
                },
                "startingActions": [
                    {
                        "details": {
                            "amount": data.amount,
                            "currencyCode": data.currencyCode,
                            "exchangeRate": data.exchangeRate,
                            "howFundsObtained": data.howFundsObtained,
                            "sourceOfFundsObtainedIndicator": data.sourceOfFundsObtainedIndicator
                        },
                        "sourcesOfFunds": [],
                        "requesters": [
                            {
                                "typeCode": 4,
                                "refId": data.requesterRefId,
                                "details": {
                                    "accounts": [
                                        {
                                            "referenceNumber": data.reportingEntityTransactionReference,
                                            "holders": []
                                        }
                                    ],
                                    "otherAccountHoldersIndicator": false,
                                    "onBehalfOfIndicator": false
                                },
                                "otherAccountHolders": [],
                                "onBehalfOfs": []
                            }
                        ]
                    }
                ],
                "senders": [],
                "involvements": [],
                "completingActions": [
                    {
                        "details": {
                            "involvementsIndicator": false
                        },
                        "beneficiaries": [
                            {
                                "typeCode": 4,
                                "refId": data.beneficiaryRefId,
                                "details": {
                                    "accounts": [
                                        {
                                            "referenceNumber": data.reportingEntityTransactionReference,
                                            "holders": []
                                        }
                                    ],
                                    "onBehalfOfIndicator": false
                                },
                                "onBehalfOfs": []
                            }
                        ],
                        "involvements": []
                    }
                ],
                "receiver": {
                    "typeCode": 8,
                    "refId": data.receiverRefId,
                    "details": {},
                }
            }
        ]
    }
};


export const mapS3ToFormData = (s3Data) => {
    console.log('s3data', s3Data)
    const initiator = s3Data.find(d => d.typeCode === 2);
    const receiver = s3Data.find(d => d.typeCode === 8);
    const merchant = s3Data.find(d => d.typeCode === 4);

    console.log(merchant?.natureOfPrincipalBusiness)

    return {
        subjectInitiatorTypeCode: initiator?.typeCode || '',
        subjectInitiatorEntityName: initiator?.nameOfEntity || '',

        subjectReceiverTypeCode: receiver?.typeCode || '',
        subjectReceiverEntityName: receiver?.nameOfEntity || '',
        subjectReceiverCountryTypeCode: receiver?.address?.countryCode || '',
        subjectReceiverEntityAddress: receiver?.address?.unstructured || '',

        subjectMerchantTypeCode: merchant?.typeCode || '',
        subjectMerchantEntityName: merchant?.nameOfEntity || '',
        subjectMerchantEntityAddressType: merchant?.addressTypeCode || '',
        subjectMerchantEntityCountry: merchant?.address?.countryCode || '',
        subjectMerchantEntityAddress: merchant?.address?.unstructured || '',
        subjectMerchantNatureOfPrincipalBusiness: merchant?.natureOfPrincipalBusiness || 'test',
        subjectMerchantRegistrationIndicator: merchant?.registrationIncorporationIndicator || false,
        subjectMerchantIncorporationRegistrationTypeCode: merchant?.registrationsIncorporations?.[0]?.typeCode || '',
        subjectMerchantRegistrationIncorporationNumber: merchant?.registrationsIncorporations?.[0]?.number || '',
        subjectMerchantIncorporationIssuingCountry: merchant?.registrationsIncorporations?.[0]?.jurisdictionOfIssueCountryCode || '',
        subjectMerchantIncorporationIssuingProvinceState: merchant?.registrationsIncorporations?.[0]?.jurisdictionOfIssueProvinceStateCode || '',
        subjectMerchantIdentificationType: merchant?.identifications?.[0]?.identifierTypeCode || '',
        subjectMerchantIdentificationNumber: merchant?.identifications?.[0]?.number || '',
        subjectMerchantIdIssuingCountry: merchant?.identifications?.[0]?.jurisdictionOfIssueCountryCode || '',
        subjectMerchantIdIssuingProvinceState: merchant?.identifications?.[0]?.jurisdictionOfIssueProvinceStateCode || '',
        subjectMerchantGivenName: merchant?.authorizedPersons?.[0]?.surname || '', // Note the swap
        subjectMerchantSurname: merchant?.authorizedPersons?.[0]?.givenName || '', // Note the swap
        subjectMerchantOtherNameInitial: null
    };
};