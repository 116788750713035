import {subjectsSchema} from './schemas/validation-schemas'
import {transformDefinitionsData} from './schemas/transform-data'
import {updateForm} from '../../../store/features/formsSlice'
import {useAppSelector} from '../../../../hooks/useAppSelector'
import {useDispatch} from 'react-redux'
import {useForm} from 'react-hook-form'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import Box from '@mui/material/Box'
import DropDownMenu from '../../components/DropDownMenu'
import Grid from '@mui/material/Grid2'
import options from './select-options'
import React, {useEffect, useMemo} from 'react'
import Skeleton from '@mui/material/Skeleton'
import TextInput from '../../components/TextInput'
import Typography from '@mui/material/Typography'

type FormData = z.infer<typeof subjectsSchema>

const FintracPageFormSubjects: React.FC = () => {
  const fintracData = useAppSelector((state) => state.forms.fintracData)
  const fintracReportSubjects = useAppSelector(
    (state) => state.forms.jsonReportDetails?.definitions
  )
  const dispatch = useDispatch()

  const {
    register,
    watch,
    setValue,
    formState: {errors, isValid},
    trigger,
    control,
  } = useForm<FormData>({
    resolver: zodResolver(subjectsSchema),
    defaultValues: {
      subjectInitiatorTypeCode: 2,
      subjectReceiverTypeCode: 8,
      subjectMerchantTypeCode: 4,
      subjectMerchantEntityAddressType: 2,
      subjectMerchantRegistrationIndicator: true,
      subjectMerchantIncorporationRegistrationTypeCode: 2,
      subjectMerchantOtherNameInitial: '',
    },
  })

  const {
    dba_name,
    first_name,
    last_name,
    documentNumber,
    issuingCountry,
    natureOfPrincipalBusiness,
  } = fintracData?.[0] || {}

  const {nameOfEntity: initiator} = fintracReportSubjects?.[0] || {}
  const {nameOfEntity: receiver, address: receiverUnstructuredAddress} =
    fintracReportSubjects?.[1] || {}
  const {
    nameOfEntity: merchant,
    address: merchantAddress,
    authorizedPersons,
    identifications,
    registrationsIncorporations,
    natureOfPrincipalBusiness: submittedNatureOfPrincipalBusiness,
  } = fintracReportSubjects?.[2] || {}

  useEffect(() => {
    trigger() // Trigger validation for all fields on load
  }, [trigger])

  useEffect(() => {
    dispatch(
      updateForm({
        formKey: 'fintracFormValidation',
        data: {isFormSubjectValid: isValid},
      })
    )
  }, [isValid, dispatch, fintracReportSubjects])

  const selectedMerchantIdIssuingCountry = watch('subjectMerchantIdIssuingCountry')
  const selectedIncorporationCountry = watch('subjectMerchantIncorporationIssuingCountry')

  const incorporationProvinceOptions = useMemo(
    () =>
      selectedIncorporationCountry === 'CA'
        ? options.ca_provinceStateItems
        : options.us_provinceStateItems,
    [selectedIncorporationCountry]
  )

  useEffect(() => {
    // STARTING AND COMPLETING ACTIONS: Identifications
    setValue(
      'subjectMerchantIdIssuingProvinceState',
      (identifications?.[0]?.jurisdictionOfIssueProvinceStateCode ??
        fintracData?.[0]?.issuingProvinceState) ||
        ''
    )
    trigger('subjectMerchantIdIssuingProvinceState')
  }, [selectedMerchantIdIssuingCountry, setValue, trigger, fintracData, identifications])

  useEffect(() => {
    // STARTING AND COMPLETING ACTIONS: Registration/Incorporation Entity
    setValue(
      'subjectMerchantIncorporationIssuingProvinceState',
      (registrationsIncorporations?.[0]?.jurisdictionOfIssueProvinceStateCode ??
        fintracData?.[0]?.issuingProvinceState) ||
        ''
    )
    trigger('subjectMerchantIncorporationIssuingProvinceState')
  }, [selectedIncorporationCountry, setValue, trigger, fintracData, registrationsIncorporations])

  useEffect(() => {
    if (fintracData) {
      const subscription = watch((value, {name}) => {
        trigger(name) // Trigger validation for the changed field
        const transformedData: ReturnType<typeof transformDefinitionsData> =
          transformDefinitionsData(value)
        dispatch(updateForm({formKey: 'fintracForm', data: transformedData}))
      })
      return () => subscription.unsubscribe()
    }
  }, [watch, trigger, dispatch, fintracData])

  useEffect(() => {
    // INITIATOR
    setValue('subjectInitiatorEntityName', initiator ?? dba_name)

    // RECEIVER
    setValue('subjectReceiverEntityName', receiver ?? dba_name)
    setValue('subjectReceiverCountryTypeCode', receiverUnstructuredAddress?.countryCode ?? 'CA')

    // STARTING AND COMPLETING ACTIONS
    setValue('subjectMerchantEntityName', merchant ?? dba_name)
    setValue(
      'subjectMerchantEntityCountry',
      merchantAddress?.countryCode ?? 'CA'
    )
    setValue(
      'subjectMerchantNatureOfPrincipalBusiness',
      submittedNatureOfPrincipalBusiness ?? natureOfPrincipalBusiness
    )

    // STARTING AND COMPLETING ACTIONS: Identifications
    setValue('subjectMerchantIdentificationType', identifications?.[0]?.identifierTypeCode ?? 3)
    setValue('subjectMerchantIdentificationNumber', identifications?.[0]?.number ?? documentNumber)
    setValue(
      'subjectMerchantIdIssuingCountry',
      identifications?.[0]?.jurisdictionOfIssueCountryCode ?? issuingCountry
    )

    // STARTING AND COMPLETING ACTIONS: Authorized Person
    setValue('subjectMerchantGivenName', (authorizedPersons?.[0]?.givenName ?? first_name) || '')
    setValue('subjectMerchantSurname', (authorizedPersons?.[0]?.surname ?? last_name) || '')
    
    // STARTING AND COMPLETING ACTIONS: Registration/Incorporation Entity
    setValue(
      'subjectMerchantRegistrationIncorporationNumber',
      registrationsIncorporations?.[0]?.number ?? documentNumber
    )
    setValue(
      'subjectMerchantIncorporationIssuingCountry',
      registrationsIncorporations?.[0]?.jurisdictionOfIssueCountryCode ?? issuingCountry
    )

    if (fintracData?.[0]?.address) {
      const {line1, line2, city, state, postal_code} = fintracData?.[0]?.address
      const address = `${line1} ${line2}, ${city}, ${state}, ${postal_code}`

      // RECEIVER
      setValue('subjectReceiverEntityAddress', receiverUnstructuredAddress?.unstructured ?? address)
      // STARTING AND COMPLETING ACTIONS
      setValue('subjectMerchantEntityAddress', merchantAddress?.unstructured ?? address)
    }
  }, [fintracReportSubjects, fintracData, setValue])

  return fintracReportSubjects || fintracData ? (
    <Box component='section' sx={{pr: 2, pl: 2, border: '1px', bgcolor: 'white'}}>
      <form>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Typography variant='h6'>Initiator </Typography>
          </Grid>
          <Grid size={4}>
            <DropDownMenu
              name='subjectInitiatorTypeCode'
              control={control}
              errors={errors?.subjectInitiatorTypeCode}
              label='Subject Type'
              options={options.subjectTypeItems}
              register={register}
              disabled={true}
            />
          </Grid>
          <Grid size={8}>
            <TextInput
              label='Entity Name'
              name='subjectInitiatorEntityName'
              register={register}
              errors={errors?.subjectInitiatorEntityName}
            />
          </Grid>
          <Grid size={12}>
            <Typography variant='h6'>Receiver</Typography>
          </Grid>
          <Grid size={3}>
            <DropDownMenu
              name='subjectReceiverTypeCode'
              control={control}
              errors={errors?.subjectReceiverTypeCode}
              label='Subject Type'
              options={options.subjectTypeItems}
              register={register}
              disabled={true}
            />
          </Grid>
          <Grid size={4}>
            <TextInput
              label='Entity Name'
              name='subjectReceiverEntityName'
              register={register}
              errors={errors?.subjectReceiverEntityName}
            />
          </Grid>
          <Grid size={2}>
            <DropDownMenu
              name='subjectReceiverCountryTypeCode'
              control={control}
              errors={errors?.subjectReceiverCountryTypeCode}
              label='Country'
              options={options.subjectCountryCodeItems}
              register={register}
            />
          </Grid>
          <Grid size={3}>
            <TextInput
              label='Entity Address'
              name='subjectReceiverEntityAddress'
              register={register}
              errors={errors?.subjectReceiverEntityAddress}
            />
          </Grid>
          <Grid size={12}>
            <Typography variant='h6'>Starting and Completing Action By</Typography>
          </Grid>
          <Grid size={3}>
            <DropDownMenu
              name='subjectMerchantTypeCode'
              control={control}
              errors={errors?.subjectMerchantTypeCode}
              label='Subject Type'
              options={options.subjectTypeItems}
              register={register}
              disabled={true}
            />
          </Grid>
          <Grid size={4}>
            <TextInput
              label='Entity Name'
              name='subjectMerchantEntityName'
              register={register}
              errors={errors?.subjectMerchantEntityName}
            />
          </Grid>
          <Grid size={5}>
            <DropDownMenu
              name='subjectMerchantEntityAddressType'
              control={control}
              errors={errors?.subjectMerchantEntityAddressType}
              label='Subject Address Type'
              options={options.addressTypeCodeItems}
              register={register}
            />
          </Grid>
          <Grid size={3}>
            <DropDownMenu
              name='subjectMerchantEntityCountry'
              control={control}
              errors={errors?.subjectMerchantEntityCountry}
              label='Country'
              options={options.subjectCountryCodeItems}
              register={register}
            />
          </Grid>
          <Grid size={9}>
            <TextInput
              label='Address'
              name='subjectMerchantEntityAddress'
              register={register}
              errors={errors?.subjectMerchantEntityAddress}
            />
          </Grid>
          <Grid size={12}>
            <TextInput
              label='Nature of Principal Business'
              name='subjectMerchantNatureOfPrincipalBusiness'
              register={register}
              errors={errors?.subjectMerchantNatureOfPrincipalBusiness}
            />
          </Grid>
          <Grid size={12}>
            <b> Identifications </b>
          </Grid>
          <Grid size={3}>
            <DropDownMenu
              name='subjectMerchantIdentificationType'
              control={control}
              errors={errors?.subjectMerchantIdentificationType}
              label='Identification Type'
              options={options.identificationTypeItems}
              register={register}
            />
          </Grid>
          <Grid size={4}>
            <TextInput
              label='Identification Number'
              name='subjectMerchantIdentificationNumber'
              register={register}
              errors={errors?.subjectMerchantIdentificationNumber}
            />
          </Grid>
          <Grid size={2}>
            <DropDownMenu
              name='subjectMerchantIdIssuingCountry'
              control={control}
              errors={errors?.subjectMerchantIdIssuingCountry}
              label='Issuing Country'
              options={options.subjectCountryCodeItems}
              register={register}
            />
          </Grid>
          <Grid size={3}>
            <DropDownMenu
              options={
                watch('subjectMerchantIdIssuingCountry') === 'CA'
                  ? options.ca_provinceStateItems
                  : options.us_provinceStateItems
              }
              control={control}
              label='Issuing province/state'
              name='subjectMerchantIdIssuingProvinceState'
              errors={errors?.subjectMerchantIdIssuingProvinceState}
            />
          </Grid>

          <Grid size={12}>
            <b> Authorized Person </b>
          </Grid>

          <Grid size={4}>
            <TextInput
              label='Given Name'
              name='subjectMerchantGivenName'
              register={register}
              errors={errors?.subjectMerchantGivenName}
            />
          </Grid>
          <Grid size={4}>
            <TextInput
              label='Surname'
              name='subjectMerchantSurname'
              register={register}
              errors={errors?.subjectMerchantSurname}
            />
          </Grid>
          <Grid size={4}>
            <TextInput
              label='Other Name Initial'
              name='subjectMerchantOtherNameInitial'
              register={register}
              errors={errors?.subjectMerchantOtherNameInitial}
              required={false}
            />
          </Grid>

          <Grid size={12}>
            <b> Registration / Incorporation of the Entity </b>
          </Grid>

          <Grid size={2}>
            <DropDownMenu
              options={options.trueFalseItems}
              label='Is registered/incorporated?'
              name='subjectMerchantRegistrationIndicator'
              control={control}
              errors={errors?.subjectMerchantRegistrationIndicator}
            />
          </Grid>
          {/* {watch('subjectMerchantRegistrationIndicator') && ( */}
          {/* <> */}
          <Grid size={2}>
            <DropDownMenu
              options={options.incorporationRegistrationTypeCodeItems}
              label='Registered or Incorporated Type'
              name='subjectMerchantIncorporationRegistrationTypeCode'
              control={control}
              errors={errors?.subjectMerchantIncorporationRegistrationTypeCode}
            />
          </Grid>
          <Grid size={3}>
            <TextInput
              label='Number'
              name='subjectMerchantRegistrationIncorporationNumber'
              register={register}
              errors={errors?.subjectMerchantRegistrationIncorporationNumber}
            />
          </Grid>
          <Grid size={2}>
            <DropDownMenu
              options={options.subjectCountryCodeItems}
              label='Issuing Country'
              name='subjectMerchantIncorporationIssuingCountry'
              control={control}
              errors={errors?.subjectMerchantIncorporationIssuingCountry}
            />
          </Grid>
          <Grid size={3}>
            <DropDownMenu
              options={incorporationProvinceOptions}
              label='Issuing province/state'
              name='subjectMerchantIncorporationIssuingProvinceState'
              control={control}
              errors={errors?.subjectMerchantIncorporationIssuingProvinceState}
            />
          </Grid>
          {/* </> */}
          {/* )} */}
        </Grid>
      </form>
    </Box>
  ) : (
    <Box sx={{pt: 0.5}}>
      <Skeleton />
      <Skeleton width='60%' />
    </Box>
  )
}

export default FintracPageFormSubjects
