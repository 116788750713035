import { getBaseUrl } from '../customFunctions/customFunctions';
import { useAuth } from '../auth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DataTable from '../components/DataTable';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import React, { useEffect, useState } from 'react';
import ConfirmDialog from '../components/ConfirmDialog';

const API_URL = process.env.REACT_APP_API_URL;

interface DataItem {
  id: string;
  amount: string;
  created_at: string;
  submerchant: {
    id: string;
    dba_name: string;
    address_id: string;
    address: {
      line1: string;
      line2: string;
      state: string;
      city: string;
    };
    business_user: {
      id: string;
      email: string;
      first_name: string;
      last_name: string;
    };
  };
  fintrac_report: {
    created_at: string;
    report_reference_number: string;
  };
}

interface ProcessedDataItem {
  id: string;
  amount: string;
  merchant: string;
  authorized_person?: string;
  address?: string;
  transaction_date: string;
  submission_date: string;
  report_reference_number: string;
}

interface ActionButton {
  field: string;
  headerName: string;
  width: number;
  renderCell: (params: any) => JSX.Element;
}

const processData = (inputData: DataItem[]): ProcessedDataItem[] => {
  return inputData.map((item) => ({
    id: item.id,
    report_reference_number: item.fintrac_report.report_reference_number,
    amount: `$${item.amount}`,
    merchant: item.submerchant.dba_name,
    transaction_date: item.created_at,
    submission_date: item.fintrac_report.created_at,
  }));
};

export default function FintracPageSubmittedReports() {
  const { logout } = useAuth();
  const base = getBaseUrl();
  const [data, setData] = useState<ProcessedDataItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [reportReferenceNumber, setReportReferenceNumber] = useState('');

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios(base + 'api/submitted-transaction-reports'); // Adjust the URL accordingly
      if (response.data) {
        setLoading(false);
        const data = await response.data;
        setData(processData(data));
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('An error occurred during the fetch', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          logout();
        }
        return Promise.reject(error);
      }
    );

    fetchData(); // Initial fetch

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [logout]);

  const navigate = useNavigate();

  const handleFintracReport = (row: ProcessedDataItem) => {
    navigate('/fintrac-page-form/' + row.merchant + '/' + row.id);
  };

  const handleConfirmDelete = () => {
    setDialogOpen(false);
    handleDeleteFintracReport();
  };

  const handleOpenDialog = (row: ProcessedDataItem) => {
    setReportReferenceNumber(row.report_reference_number);
    setDialogOpen(true);
  };

  const handleDeleteFintracReport = async () => {
    const url = `${API_URL}/delete-report`;
    const body = {
      reportDetails: {
        reportTypeCode: 145,
        submitTypeCode: 5,
        reportingEntityNumber: 171132,
        submittingReportingEntityNumber: 171132,
        reportingEntityReportReference: reportReferenceNumber,
        reportSubmitReasonCode: 3,
      },
    };

    try {
      setLoading(true);
      const response = await axios.post(url, body, {
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
        },
      })

      console.log('Success:', response.data);

      await fetchData();
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setReportReferenceNumber('');
      setLoading(false);
    }
  };

  const actionButtons: ActionButton[] = [
    {
      field: 'actions',
      headerName: 'ACTIONS',
      width: 220,
      renderCell: (params) => (
        <div>
          <Button
            variant='contained'
            color='info'
            size='small'
            onClick={() => handleFintracReport(params.row)}
            sx={{
              margin: '0px 5px',
            }}
          >
            <EditNoteIcon /> Update
          </Button>
          <Button
            variant='contained'
            color='error'
            size='small'
            onClick={() => handleOpenDialog(params.row)}
            sx={{
              margin: '0px 16px',
            }}
          >
            <FolderDeleteIcon /> Delete
          </Button>
          <ConfirmDialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            onConfirm={handleConfirmDelete}
            title='Delete Fintrac Report'
            content='Are you sure you want to delete this report? This action cannot be undone.'
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataTable data={data} actionButtons={actionButtons} sortField='submission_date'/>
      )}
    </>
  );
}
