import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FormsState {
    [key: string]: any; // Key is dynamic (e.g., userForm, detailsForm)
}

const initialState: FormsState = {};

const formsSlice = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        setFintracData: (state, action) => {
            state.fintracData = action.payload;
        },
        setJsonReportDetails: (state, action) => {
            state.jsonReportDetails = action.payload;
        },
        updateForm: (
            state,
            action: PayloadAction<{ formKey: string; data: any }>
        ) => {
            const { formKey, data } = action.payload;
            state[formKey] = { ...state[formKey], ...data };
        },
        resetForm: (state, action: PayloadAction<{ formKey: string }>) => {
            const { formKey } = action.payload;
            state[formKey] = {};
        },
    },
});

export const { setFintracData, setJsonReportDetails, updateForm, resetForm } = formsSlice.actions;

export default formsSlice.reducer;
