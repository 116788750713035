import {getBaseUrl} from '../customFunctions/customFunctions'
import {useAuth} from '../auth'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import DataTable from '../components/DataTable'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import React, {useEffect, useState} from 'react'

interface DataItem {
  id: string
  amount: string
  created_at: string
  submerchant: {
    id: string
    dba_name: string
    address_id: string
    address: {
      line1: string
      line2: string
      state: string
      city: string
    }
    business_user: {
      id: string
      email: string
      first_name: string
      last_name: string
    }
  }
}

interface ProcessedDataItem {
  id: string
  amount: string
  merchant: string
  authorized_person?: string
  address?: string
  transaction_date: string
}

interface ActionButton {
  field: string
  headerName: string
  width: number
  renderCell: (params: any) => JSX.Element
}

const processData = (inputData: DataItem[]): ProcessedDataItem[] => {
  return inputData.map((item) => ({
    id: item.id,
    amount: `$${item.amount}`, // Convert amount to a number
    merchant: item.submerchant.dba_name,
    transaction_date: item.created_at,
  }))
}

export default function FintracPagePending() {
  const {logout} = useAuth()
  const base = getBaseUrl()
  const [data, setData] = useState<ProcessedDataItem[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios(base + 'api/fintrac-report') // Adjust the URL accordingly
        if (response.data) {
          setLoading(false)
          const data = await response.data
          setData(processData(data))
        } else {
          console.error('Failed to fetch data')
        }
      } catch (error) {
        console.error('An error occurred during the fetch', error)
      } finally {
        setLoading(false)
      }
    }
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          logout()
        }
        return Promise.reject(error)
      }
    )
    fetchData()
    return () => {
      axios.interceptors.response.eject(interceptor)
    }
  }, [logout])

  const navigate = useNavigate()

  const handleFintracReport = (row: ProcessedDataItem) => {
    navigate('/fintrac-page-form/' + row.merchant + '/' + row.id)
  }

  const actionButtons: ActionButton[] = [
    {
      field: 'actions',
      headerName: 'ACTIONS',
      width: 300,
      renderCell: (params) => (
        <div>
          <Button
            variant='contained'
            color='success'
            size='small'
            onClick={() => handleFintracReport(params.row)}
            sx={{
              margin: '0px 16px', // Top/Bottom: 8px, Left/Right: 16px
            }}
          >
            <DriveFileRenameOutlineIcon/> Generate Fintrac Report
          </Button>
        </div>
      ),
    },
  ]

  return (
    <>
      {loading ? (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <CircularProgress />
        </div>
      ) : (
        <DataTable data={data} actionButtons={actionButtons} sortField='transaction_date' />
      )}
    </>
  )
}
