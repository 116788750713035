import {reportDetailsSchema} from './schemas/validation-schemas'
import {transformReportDetailsData} from './schemas/transform-data'
import {updateForm} from '../../../store/features/formsSlice'
import {useAppSelector} from '../../../../hooks/useAppSelector'
import {useDispatch} from 'react-redux'
import {useForm} from 'react-hook-form'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import Box from '@mui/material/Box'
import CustomDatetimePicker from '../../components/CustomDatetimePicker'
import dayjs from 'dayjs'
import DropDownMenu from '../../components/DropDownMenu'
import Grid from '@mui/material/Grid2'
import options from './select-options'
import React, {useEffect} from 'react'
import Skeleton from '@mui/material/Skeleton'
import TextInput from '../../components/TextInput'

type FormData = z.infer<typeof reportDetailsSchema>

const FintracPageFormReportDetails: React.FC = () => {
  const fintracData = useAppSelector((state) => state.forms.fintracData)
  const fintracReportDetails = useAppSelector(
      (state) => state.forms.jsonReportDetails?.reportDetails
    )
  const dispatch = useDispatch()
  const env = process.env

  const {
    register,
    watch,
    formState: {errors, isValid},
    trigger,
    control,
    setValue,
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(reportDetailsSchema),
    defaultValues: {
      reportTypeCode: 145,
      submitTypeCode: 1,
      activitySectorCode: 11,
      aggregationTypeCode: 4,
      reportingEntityNumber: Number(env.REACT_APP_FINTRAC_REPORTING_ENTITY_NUMBER),
      submittingReportingEntityNumber: Number(env.REACT_APP_FINTRAC_REPORTING_ENTITY_NUMBER),
      reportingEntityContactId: Number(env.REACT_APP_FINTRAC_ENTITY_CONTACT_NUMBER),
      ministerialDirectiveCode: ' ',
    },
  })

  useEffect(() => {
    trigger() 
  }, [trigger])

  useEffect(() => {
    const subscription = watch((value, {name}) => {
      trigger(name) 
      const transformedData: ReturnType<typeof transformReportDetailsData> =
        transformReportDetailsData(value)
      dispatch(updateForm({formKey: 'fintracForm', data: transformedData}))
    })
    return () => subscription.unsubscribe()
  }, [watch, trigger, dispatch])

  useEffect(() => {
    dispatch(
      updateForm({
        formKey: 'fintracFormValidation',
        data: {isFormReportDetailsValid: isValid},
      })
    )
  }, [isValid, dispatch, errors])

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString)

    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    return `${year}${month}${day}`
  }

  const abbreviateBusiness = (input: string): string => {
    const cleanedInput = input.replace(/[^a-zA-Z0-9\s]/g, '')
    const formattedInput = cleanedInput.replace(/\s+/g, '-')

    return formattedInput
  }

  useEffect(() => {
    // reset();
    if (fintracData?.[0]) {
      const transactionDate = formatDate(fintracData?.[0]?.created_at)
      const businessName = abbreviateBusiness(fintracData?.[0]?.dba_name)
      const lastHyphenValue = fintracData?.[0]?.id.split('-').pop()
      const reportReference = `${businessName}_EFTR_${transactionDate}_${lastHyphenValue}`
      const transactionCreatedAt = dayjs(fintracData?.[0]?.created_at)
      const isSubmitted = fintracData?.[0]?.is_submitted ? 2 : 1

      setValue('reportingEntityReportReference', reportReference)
      setValue('periodStart', transactionCreatedAt.startOf('day').toISOString())
      setValue('periodEnd', transactionCreatedAt.endOf('day').toISOString())
      setValue('submitTypeCode', isSubmitted)
    }
  }, [fintracData, setValue, reset])

  return fintracData ? (
    <div>
      <Box component='section' sx={{pr: 2, pl: 2, pb: 2, border: '1px', bgcolor: 'white'}}>
        <form>
          <Grid container spacing={2}>
            <Grid size={4}>
              <DropDownMenu
                name='reportTypeCode'
                control={control}
                disabled={true}
                errors={errors?.reportTypeCode}
                label='Report Type'
                options={options.reportTypeCodeItems}
                type={'number'}
              />
            </Grid>
            <Grid size={4}>
              <DropDownMenu
                name='submitTypeCode'
                control={control}
                disabled={true}
                errors={errors?.submitTypeCode}
                label='Submit Type'
                options={options.submitTypeCodeItems}
                type={'number'}
              />
            </Grid>
            <Grid size={4}>
              <DropDownMenu
                name='activitySectorCode'
                control={control}
                disabled={true}
                errors={errors?.activitySectorCode}
                label='Activity Sector'
                options={options.activitySectorCode}
                type={'number'}
              />
            </Grid>

            <Grid size={4}>
              <TextInput
                label='Reporting Entity Report Reference Number'
                name='reportingEntityReportReference'
                register={register}
                errors={errors?.reportingEntityReportReference}
              />
            </Grid>
            <Grid size={4}>
              <TextInput
                label='Reporting Entity Number'
                name='reportingEntityNumber'
                type={'number'}
                register={register}
                errors={errors?.reportingEntityNumber}
                disabled={true}
              />
            </Grid>
            <Grid size={4}>
              <TextInput
                label='Submitting Reporting Entity Number'
                name='submittingReportingEntityNumber'
                type={'number'}
                register={register}
                errors={errors?.submittingReportingEntityNumber}
                disabled={true}
              />
            </Grid>

            <Grid size={4}>
              <DropDownMenu
                options={options.aggregationTypeItems}
                label='Aggregation Type'
                name='aggregationTypeCode'
                errors={errors?.aggregationTypeCode}
                type={'number'}
                control={control}
              />
            </Grid>
            <Grid size={4}>
              <CustomDatetimePicker
                control={control}
                errors={errors?.periodStart}
                label={'Transaction start date and time'}
                name={'periodStart'}
              />
            </Grid>
            <Grid size={4}>
              <CustomDatetimePicker
                control={control}
                defaultValue={dayjs().endOf('day')}
                errors={errors?.periodEnd}
                label={'Transaction end date and time'}
                maxDateTime={dayjs(watch('periodStart')).endOf('day')}
                minDateTime={watch('periodStart')}
                name={'periodEnd'}
              />
            </Grid>

            <Grid size={6}>
              <TextInput
                label='Reporting Entity Contact Number'
                name='reportingEntityContactId'
                type={'number'}
                register={register}
                errors={errors?.reportingEntityContactId}
                disabled={true}
              />
            </Grid>
            <Grid size={6}>
              <TextInput
                label='Ministerial Directive'
                name='ministerialDirectiveCode'
                register={register}
                errors={errors?.ministerialDirectiveCode}
                required={false}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  ) : (
    <Box sx={{pt: 0.5}}>
      <Skeleton />
      <Skeleton width='60%' />
    </Box>
  )
}

export default FintracPageFormReportDetails
