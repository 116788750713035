const options = {
    // these options are defined on https://www148.fintrac-canafe.canada.ca/swagger
    eftTypeCodeItems: [
        { value: 1, label: 'SWIFT' },
        { value: 2, label: 'non-SWIFT' },
    ],
    regulatoryExceptionAppliedItems: [
        { value: false, label: 'No' },
        { value: true, label: 'Yes' },
    ],
    thresholdIndicatorItems: [
        { value: false, label: 'Below threshold' },
        { value: true, label: 'Above threshold' },
    ],
    initiatorLocationItems: [
        { value: '1', label: '515 CONSUMERS RD, UNIT 210 NORTH YORK, ON M2J 4Z2' },
        { value: '2', label: '38 John St., Thornhill, ON L3T 1X8' },
    ],
    initiatorTypeCodeItems: [
        { value: 0, label: 'Not Applicable' },
        { value: 1, label: 'Person name' },
        { value: 2, label: 'Entity name' },
        { value: 7, label: 'Person name and address' },
        { value: 8, label: 'Entity namen and address' },
    ],
    reportTypeCodeItems: [
        { value: 14, label: 'Large Virtual Currency Transaction Report (LVCTR)' },
        { value: 102, label: 'Suspicious transaction report (STR)' },
        { value: 106, label: 'Large cash transaction report (LCTR)' },
        { value: 113, label: 'Casino disbursement report (CDR)' },
        { value: 145, label: 'Electronic funds transfer report (EFTR)' },
    ],
    submitTypeCodeItems: [
        { value: 1, label: 'Submit' },
        { value: 2, label: 'Update' },
        { value: 5, label: 'Delete' },
    ],
    activitySectorCode: [
        { value: 11, label: 'Money services business' },
    ],
    aggregationTypeItems: [
        { value: 1, label: 'Beneficiary', disabled: true },
        { value: 3, label: 'On behalf of (i.e. 3rd party)', disabled: true },
        { value: 4, label: 'Not applicable' },
        { value: 5, label: 'Requester', disabled: true },
    ],
    subjectTypeItems: [
        { value: 2, label: 'Entity Name' },
        { value: 4, label: 'Entity Details' },
        { value: 8, label: 'Entity Name and Address' },
    ],
    addressTypeCodeItems: [
        { value: 1, label: 'Structured Address', disabled: true },
        { value: 2, label: 'Unstructured Address' },
    ],
    subjectCountryCodeItems: [
        { value: 'CA', label: 'Canada' },
        { value: 'US', label: 'United States' },
    ],
    trueFalseItems: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ],
    incorporationRegistrationTypeCodeItems: [
        { value: 1, label: 'Registered' },
        { value: 2, label: 'Incorporated' },
        { value: 4, label: 'Registered and incorporated' },
        { value: 5, label: 'Unknown' },
    ],
    identificationTypeItems: [
        { value: 1, label: 'Articles of association' },
        { value: 2, label: 'Certificate of corporate status' },
        { value: 3, label: 'Certificate of incorporation' },
        { value: 4, label: 'Letter/Notice of assessment' },
        { value: 5, label: 'Partnership agreement' },
        { value: 6, label: 'Annual report' },
        { value: 7, label: 'Other', disabled: true },
    ],
    ca_provinceStateItems: [
        { value: 'AB', label: 'Alberta' },
        { value: 'BC', label: 'British Columbia' },
        { value: 'MB', label: 'Manitoba' },
        { value: 'NB', label: 'New Brunswick' },
        { value: 'NL', label: 'Newfoundland and Labrador' },
        { value: 'NT', label: 'Northwest Territories' },
        { value: 'NS', label: 'Nova Scotia' },
        { value: 'NU', label: 'Nunavut' },
        { value: 'ON', label: 'Ontario' },
        { value: 'PE', label: 'Prince Edward Island' },
        { value: 'QC', label: 'Quebec' },
        { value: 'SK', label: 'Saskatchewan' },
        { value: 'YT', label: 'Yukon' },
    ],
    us_provinceStateItems: [
        { value: 'AL', label: 'Alabama' },
        { value: 'AK', label: 'Alaska' },
        { value: 'AZ', label: 'Arizona' },
        { value: 'AR', label: 'Arkansas' },
        { value: 'CA', label: 'California' },
        { value: 'CO', label: 'Colorado' },
        { value: 'CT', label: 'Connecticut' },
        { value: 'DE', label: 'Delaware' },
        { value: 'DC', label: 'District of Columbia' },
        { value: 'FL', label: 'Florida' },
        { value: 'GA', label: 'Georgia' },
        { value: 'HI', label: 'Hawaii' },
        { value: 'ID', label: 'Idaho' },
        { value: 'IL', label: 'Illinois' },
        { value: 'IN', label: 'Indiana' },
        { value: 'IA', label: 'Iowa' },
        { value: 'KS', label: 'Kansas' },
        { value: 'KY', label: 'Kentucky' },
        { value: 'LA', label: 'Louisiana' },
        { value: 'ME', label: 'Maine' },
        { value: 'MD', label: 'Maryland' },
        { value: 'MA', label: 'Massachusetts' },
        { value: 'MI', label: 'Michigan' },
        { value: 'MN', label: 'Minnesota' },
        { value: 'MS', label: 'Mississippi' },
        { value: 'MO', label: 'Missouri' },
        { value: 'MT', label: 'Montana' },
        { value: 'NE', label: 'Nebraska' },
        { value: 'NV', label: 'Nevada' },
        { value: 'NH', label: 'New Hampshire' },
        { value: 'NJ', label: 'New Jersey' },
        { value: 'NM', label: 'New Mexico' },
        { value: 'NY', label: 'New York' },
        { value: 'NC', label: 'North Carolina' },
        { value: 'ND', label: 'North Dakota' },
        { value: 'OH', label: 'Ohio' },
        { value: 'OK', label: 'Oklahoma' },
        { value: 'OR', label: 'Oregon' },
        { value: 'PA', label: 'Pennsylvania' },
        { value: 'RI', label: 'Rhode Island' },
        { value: 'SC', label: 'South Carolina' },
        { value: 'SD', label: 'South Dakota' },
        { value: 'TN', label: 'Tennessee' },
        { value: 'TX', label: 'Texas' },
        { value: 'UT', label: 'Utah' },
        { value: 'VT', label: 'Vermont' },
        { value: 'VA', label: 'Virginia' },
        { value: 'WA', label: 'Washington' },
        { value: 'WV', label: 'West Virginia' },
        { value: 'WI', label: 'Wisconsin' },
        { value: 'WY', label: 'Wyoming' },
    ],
    currencyCodeItems: [
        { value: 'CAD', label: 'CAD' },
        { value: 'USD', label: 'USD' },
    ],
};

export default options;