import { z } from 'zod';

export const reportDetailsSchema = z.object({
    reportTypeCode: z.number().min(1, "Report Type is required"),
    submitTypeCode: z.union([z.string().min(1, "Submit Type is required"), z.number()]),
    activitySectorCode: z.union([z.string().min(1, "Activity Sector Code is required"), z.number()]),
    ministerialDirectiveCode: z.string().nullable(),
    reportingEntityNumber: z.number().min(1, "Subject Type is required"),
    submittingReportingEntityNumber: z.number(),
    reportingEntityReportReference: z.string().min(1, "Reporting entity report reference number is required"),
    aggregationTypeCode: z.number(),
    periodStart: z.string().min(1, "Transaction start date and time is required"),
    periodEnd: z.string().min(1, "Transaction end date and time is required"),
    reportingEntityContactId: z.number(),
});

export const subjectsSchema = z.object({
    subjectInitiatorTypeCode: z.union([z.string().min(1, "Subject Type is required"), z.number()]),
    subjectInitiatorEntityName: z.string().min(1, "Entity Name is required"),
    subjectReceiverTypeCode: z.union([z.string().min(1, "Subject Type is required"), z.number()]),
    subjectReceiverEntityName: z.string().min(1, "Entity Name is required"),
    subjectReceiverCountryTypeCode: z.string().min(1, "Entity country is required"),
    subjectReceiverEntityAddress: z.string().min(1, "Entity Address is required"),
    subjectMerchantTypeCode: z.union([z.string().min(1, "Subject Type is required"), z.number()]),
    subjectMerchantEntityName: z.string().min(1, "Entity Name is required"),
    subjectMerchantEntityAddressType: z.union([z.string().min(1, "Entity Address Type is required"), z.number()]),
    subjectMerchantEntityCountry: z.string().min(1, "Entity Country is required"),
    subjectMerchantEntityAddress: z.string().min(1, "Entity Address is required"),
    subjectMerchantIdentificationType: z.union([z.string().min(1, "Entity Identification Type is required"), z.number()]),
    subjectMerchantIdentificationNumber: z.string().min(1, "Entity Identification Number is required"),
    subjectMerchantIdIssuingCountry: z.string().min(1, "Entity ID issuing country is required"),
    subjectMerchantIdIssuingProvinceState: z.string().min(1, "Entity ID issuing province/state is required"),
    subjectMerchantGivenName: z.string().min(1, "Given Name is required"),
    subjectMerchantSurname: z.string().min(1, "Surname is required"),
    subjectMerchantOtherNameInitial: z.string().nullable(),
    subjectMerchantRegistrationIndicator: z.union([z.string().min(1, "Registration/Incorporation Type is required"), z.boolean()]),
    subjectMerchantIncorporationRegistrationTypeCode: z.union([z.string().min(1, "Registration/Incorporation Type is required"), z.number()]),
    subjectMerchantRegistrationIncorporationNumber: z.string().min(1, "Registration/Incorporation Number is required"),
    subjectMerchantIncorporationIssuingCountry: z.string().min(1, "Issuing country is required"),
    subjectMerchantIncorporationIssuingProvinceState: z.string().min(1, "Issuing province/state is required"),
    subjectMerchantNatureOfPrincipalBusiness: z.string().min(1, "Nature of principal business is required")
});

// todo: simplify these refine methods


export const transactionsSchema = z.object({
    eftTypeCode: z.number().min(1, 'EFT type is required'),
    thresholdIndicator: z.boolean(),
    regulatoryExceptionIndicator: z.boolean(),
    dateTimeOfTransaction: z.string().min(1, "Date and time of transactions is required"),
    reportingEntityTransactionReference: z.string().min(1, "Reporting entity transaction reference is required"),
    // additionalPartiesSendingIndicator: z.boolean(),
    // additionalPartiesEffectingSwiftIndicator: z.boolean(),
    // additionalPaymentInformations: z.array(z.any()),
    reportingEntityLocationId: z.string().min(1, 'Reporting entity location is required'),
    typeCode: z.number().min(1, 'Type Code is required'),
    refId: z.string().min(1, "Subject reference is required"),
    amount: z.string().min(1, 'Amount is required'),
    currencyCode: z.string().min(1, "Currency is required"),
    sourceOfFundsObtainedIndicator: z.boolean(),
    // exchangeRate: z.string().nullable(),
    // howFundsObtained: z.string().nullable(),
    requesterRefId: z.string().min(1, "Requester is required"),
    beneficiaryTypeCode: z.number().min(1, 'Beneficiary type is required'),
    beneficiaryRefId: z.string().min(1, "Beneficiary is required"),
    receiverTypeCode: z.number().min(1, 'Receiver type is required'),
    receiverRefId: z.string().min(1, "Beneficiary is required")
});


