import React, {forwardRef, useState} from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import {UseFormRegister} from 'react-hook-form'
import {FormHelperText} from '@mui/material'
import {Controller} from 'react-hook-form'

interface DropDownMenuProps {
  options: Array<{value: any; label: string; disabled?: boolean}>
  label: string
  defaultValue?: any // Allow a default value prop
  name: any // The key to register with react-hook-form
  disabled?: boolean
  register?: UseFormRegister<any>
  errors?: any
  required?: boolean
  type?: any
  control: any
}

const DropDownMenu = forwardRef<HTMLSelectElement, DropDownMenuProps>(
  (
    {
      options,
      label,
      name,
      disabled,
      register,
      errors,
      required = true,
      type = 'string',
      control, // Added control prop for use with Controller
    },
    ref
  ) => {
    const fieldId = label.replace(/\s+/g, '-').toLowerCase()
    const fieldLabelId = `${fieldId}-label`

    return (
      <FormControl fullWidth size='small' error={!!errors}>
        <InputLabel required={required} id={fieldLabelId}>
          {label}
        </InputLabel>
        <Controller
          control={control} // Control the value with react-hook-form's Controller
          name={name}
          render={({field}) => (
            <Select
              {...field} // Pass down the field (value, onChange, onBlur)
              labelId={fieldLabelId}
              id={fieldId}
              label={label}
              disabled={disabled}
              value={options.some((item) => item.value === field.value) ? field.value : ''}
            >
              {options.map((item, index) => (
                <MenuItem key={index} value={item.value} disabled={item.disabled}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          )}
          rules={{required}}
          defaultValue='' // Ensure the initial value is defined
        />
        {errors && <FormHelperText>{errors?.message}</FormHelperText>}
      </FormControl>
    )
  }
)

export default DropDownMenu
