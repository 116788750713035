import { configureStore } from '@reduxjs/toolkit';
import formReducer from './features/formsSlice';

export const store = configureStore({
    reducer: {
        forms: formReducer,
    },
});

// Export types for usage in components
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
