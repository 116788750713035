import React, {forwardRef} from 'react'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import {UseFormRegister} from 'react-hook-form'
import {boolean} from 'yup'

interface TextInputProps {
  label: string
  register: UseFormRegister<any> // Update this type as needed
  name: string
  errors?: any
  required?: boolean
  type?: 'number' | 'string'
  defaultValue?: any
  disabled?: boolean
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {label, register, name, errors, required = true, type = 'string', defaultValue, disabled},
    ref
  ) => {
    const errorMessage = errors?.message

    const fieldId = name.replace(/\s+/g, '-').toLowerCase()
    const fieldLabelId = `${fieldId}-id`

    return (
      <FormControl fullWidth>
        <TextField
          sx={{flex: 1}}
          id={fieldLabelId}
          label={label}
          variant='outlined'
          size='small'
          inputRef={ref}
          {...register(name, {
            valueAsNumber: type === 'number',
          })}
          error={!!errors} // Ensure error is set correctly
          helperText={typeof errorMessage === 'string' ? errorMessage : ''}
          required={required}
          defaultValue={defaultValue}
          disabled={disabled}
          type={type}
        />
      </FormControl>
    )
  }
)

export default TextInput
