import React, {useState, forwardRef} from 'react'
import FormControl from '@mui/material/FormControl'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker'
import {Controller} from 'react-hook-form'
import dayjs, {Dayjs} from 'dayjs'
import {FormHelperText} from '@mui/material'

interface CustomDateTimePickerProps {
  control: any
  minDateTime?: any
  maxDateTime?: any
  name: string
  defaultValue?: Dayjs
  errors?: any
  label: string
}

const CustomDateTimePicker = forwardRef<HTMLDivElement, CustomDateTimePickerProps>(
  ({control, minDateTime, maxDateTime, name, defaultValue, errors, label}, ref) => {
    return (
      <div>
        <FormControl fullWidth error={!!errors}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue?.toISOString() ?? dayjs().toISOString()}
              render={({field}) => (
                <DateTimePicker
                  label={label}
                  {...field}
                  minDateTime={minDateTime ? dayjs(minDateTime) : undefined}
                  maxDateTime={maxDateTime ? dayjs(maxDateTime) : undefined}
                  onChange={(newDate) => {
                    field.onChange(newDate?.toISOString())
                  }}
                  value={field.value ? dayjs(field.value) : dayjs()}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      variant: 'outlined',
                      size: 'small',
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
          {errors && <FormHelperText>{errors?.message}</FormHelperText>}
        </FormControl>
      </div>
    )
  }
)

export default CustomDateTimePicker
